import React from 'react';
import { graphql } from 'gatsby';
import { Container } from 'react-bootstrap';
import { FeatureSections } from '../components/global/FeatureSections';
import { FlowToContact } from '../components/global/FlowToContact';
import { HeadElement } from '../components/global/HeadElement';
import { Specifications } from '../components/global/Specifications';
import { UseCases } from '../components/global/UseCases';
import { IngestHeader } from '../components/ingest/IngestHeader';

function Ingest({ data }) {
   const { contentFeatures, contentSpecs, useCases } = data;

   const contentBlock1 = contentFeatures.edges.filter((element) => element.node.frontmatter.group === 1);
   const contentBlock2 = contentFeatures.edges.filter((element) => element.node.frontmatter.group === 2);

   return (
      <div>
         <HeadElement namespace="ingest" />
         <Container>
            <IngestHeader />
         </Container>
         <FeatureSections variant="vture-yellow" content={contentBlock1} />
         <Container>
            <UseCases content={useCases} />
         </Container>
         <FeatureSections variant="vture-yellow" content={contentBlock2} />
         <Container>
            <Specifications content={contentSpecs} />
            <FlowToContact variant="vture-yellow" />
         </Container>
      </div>
   );
}

export default Ingest;

export const query = graphql`
   query ($language: String!) {
      locales: allLocale(filter: { language: { eq: $language } }) {
         edges {
            node {
               ns
               data
               language
            }
         }
      }
      contentFeatures: allMarkdownRemark(
         filter: { frontmatter: { route: { eq: "/ingest" }, type: { eq: "features" }, language: { eq: $language } } }
         sort: { fields: frontmatter___order, order: ASC }
      ) {
         edges {
            node {
               id
               html
               frontmatter {
                  icon
                  image {
                     childImageSharp {
                        gatsbyImageData(width: 700, placeholder: BLURRED)
                     }
                  }
                  imageAlt
                  isHeader
                  order
                  group
                  language
               }
            }
         }
      }
      useCases: allMarkdownRemark(
         filter: { frontmatter: { route: { eq: "/ingest" }, type: { eq: "usecases" }, language: { eq: $language } } }
         sort: { fields: frontmatter___order, order: ASC }
      ) {
         edges {
            node {
               id
               html
               frontmatter {
                  isHeader
                  order
                  title
                  language
               }
            }
         }
      }
      contentSpecs: allMarkdownRemark(
         filter: { frontmatter: { route: { eq: "/ingest" }, type: { eq: "specs" }, language: { eq: $language } } }
         sort: { fields: frontmatter___order, order: ASC }
      ) {
         edges {
            node {
               id
               html
               frontmatter {
                  isHeader
                  order
                  language
               }
            }
         }
      }
   }
`;
