import React from 'react';
import { SectionHeader } from './SectionHeader';
import { ParallaxContentWrapper } from './animation/ParallaxContentWrapper';

export function Specifications({ content }) {
   if (!content || !content.edges.length) {
      return null;
   }

   return (
      <>
         {content.edges.map(({ node }, idx) => {
            const content = <div dangerouslySetInnerHTML={{ __html: node.html }}></div>;

            if (node.frontmatter.isHeader) {
               return (
                  <section key={idx} id={idx}>
                     <SectionHeader content={content} />
                  </section>
               );
            }
            return (
               <section key={idx} className="pb-5">
                  <ParallaxContentWrapper>{content}</ParallaxContentWrapper>
               </section>
            );
         })}
      </>
   );
}
