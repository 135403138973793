import React from 'react';
import { Hero } from '../global/Hero';
import { MainFeatures } from '../global/MainFeatures';
import { useTranslation } from 'gatsby-plugin-react-i18next';

export function IngestHeader() {
   const { t } = useTranslation('ingest');

   return (
      <div className="header-wrapper">
         <div>
            <Hero intent="vture-yellow" namespace="ingest" />
            <MainFeatures
               intent="vture-yellow"
               elements={[
                  { title: t('features.feature1.title'), subtitle: t('features.feature1.subtitle'), icon: 'smile' },
                  { title: t('features.feature2.title'), subtitle: t('features.feature2.subtitle'), icon: 'check-circle' },
                  { title: t('features.feature3.title'), subtitle: t('features.feature3.subtitle'), icon: 'stream' },
               ]}
            />
         </div>
      </div>
   );
}
